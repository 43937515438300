import { HelpComponentType } from "../../navigation/HelpNavigator";
import styles from "./styles.module.css";

interface WarrantyServiceInAppDashboardProps extends HelpComponentType {
  userEmail: string;
}
const IS_DEV = false;
export default function WarrantyServiceInAppDashboard({
  currentScreenSetter,
  userEmail,
}: WarrantyServiceInAppDashboardProps) {

  let iframeEmail = 'https://service-program-dashboard.robotical.io';
  if (IS_DEV) {
    iframeEmail = `http://localhost:3000`;
  }

  return (
    <div className={styles.warrantyDasbhoardInAppContainer}>
      <iframe src={`${iframeEmail}/?email=${userEmail}&inApp=true`} allow="bluetooth" />
    </div>
  );
}
